<template>
  <div class="about">
    <NavBar />

    <div class="container mt-4">
      <p class="h5">Email</p>
      <p>
        <a
          href="mailto:hi@zhuchongling.com"
          target="_blank"
        >hi@zhuchongling.com</a>
      </p>

      <p class="h5">Social Media</p>
      <a href="https://www.linkedin.com/in/chongling-zhu/" target="_blank" rel="noopener">
        <font-awesome-icon :icon="['fab', 'linkedin']" size="2x" />
      </a> <a href="https://www.facebook.com/chonglingzhu" target="_blank" rel="noopener">
        <font-awesome-icon :icon="['fab', 'facebook-square']" size="2x" />
      </a>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import NavBar from '@/components/NavBar.vue'
export default {
  name: 'About',
  components: {
    NavBar
  }
}
</script>
